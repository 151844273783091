import ReactGA from 'react-ga4';
import config from 'config/config.json';

/**
 * initGA - Initialise google analytics.
 * @param {string} trackingID
 */
export const initGA = (trackingID) => {
  if (config.APP_ENV === 'production') {
    ReactGA.initialize(trackingID);
  }
};

/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 * @param {int} value
 */
export const Event = (category = '', action = '', label = '', value = '') => {
  if (config.APP_ENV === 'production') {
    ReactGA.event({
      category,
      action,
      label,
      value,
    });
  }
};
